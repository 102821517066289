import { Component, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import { AngularPlugin, IErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { VersionCheckService } from '../app/core/services/version-check.service';

import { environment } from '../environments/environment';
import { SpinnerComponent } from './core/spinner/spinner.cmp';
import { MainNavComponent } from './core/main-nav/main-nav.cmp';

@Component({
    selector: 'my-app',
    templateUrl: `./app.cmp.html`,
    imports: [
        MainNavComponent,
        RouterOutlet,
        SpinnerComponent,
    ]
})

export class AppComponent {
    authService = inject(AuthService);
    private _router = inject(Router);
    private versionCheckService = inject(VersionCheckService);

    name = 'Angular';

    public authenticated: boolean = false;
    public isLoading: boolean = false;

    constructor() {
        this.versionCheckService.initVersionCheck('./version.json');
    }

    ngOnInit(): void {
        this.authService.user$.subscribe(user => {
            if (user) {
                this.addAppInsights(user);
            }
        });
    }

    private addAppInsights(user: User) {
        var angularPlugin = new AngularPlugin();
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.appInsightsKey,
                extensions: [angularPlugin],
                extensionConfig: {
                    [angularPlugin.identifier]: { 
                        router: this._router,
                        errorServices: [new CustomErrorHandler()]  
                    }
                }
            }
        });

        appInsights.loadAppInsights();
        if (user?.email) {
            appInsights.setAuthenticatedUserContext(user.email, undefined, true);
        }
    }
}

export class CustomErrorHandler implements IErrorService {  
    constructor() {
    }
  
    handleError(error: any) {
      if (error && !environment.production) {
        console.error(error);
      }
    }
}