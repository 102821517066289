import { ChangeDetectorRef, Component, Renderer2, inject } from '@angular/core';
import { DOCUMENT, AsyncPipe } from '@angular/common';

import { environment } from '../../../environments/environment';

import { AppUser, UserRole } from '../../models';
import { UserProfileService } from 'app/core/user-profile.svc';
import { NotificationDTO, NotificationTypeEnum, NotificationsClient } from '../../core/services/dataservice.svc';

import { AuthService } from '@auth0/auth0-angular';
import { CookieService } from 'ngx-cookie-service';
import { UserResolverService } from '../services/user-resolver.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoService, TranslocoDirective } from '@jsverse/transloco';
import { WhatsNewModalComponent } from '../../shared/whats-new-modal/whats-new-modal.component';


@Component({
    selector: 'main-nav',
    templateUrl: './main-nav.cmp.html',
    styleUrls: ['main-nav.cmp.scss'],
    imports: [TranslocoDirective, RouterLink, RouterLinkActive, WhatsNewModalComponent, AsyncPipe]
})
export class MainNavComponent {
    translate = inject(TranslocoService);
    authService = inject(AuthService);
    private _notificationClient = inject(NotificationsClient);
    private userProfileService = inject(UserProfileService);
    private _userResolverService = inject(UserResolverService);
    private _cookieService = inject(CookieService);
    private _router = inject(Router);
    private _cdf = inject(ChangeDetectorRef);
    private _renderer = inject(Renderer2);
    private doc = inject<Document>(DOCUMENT);


    public showDropdownMenu = false;
    public appUser: AppUser;
    public environment: string;

    public showMenu: boolean = false;
    public userInfo: any;

    public isReleaseNoteModalVisible = false;
    public isLoading = false;
    public whatsNewNotifications: NotificationDTO[] = [];

    constructor() {
        this._renderer.listen('window', 'click', (e: Event) => {
            let target = <any>e.target;
            if (target.id == "menu-wrapper" || target.class == '' ) {
                return;
            }

            if(this.showMenu) {
                if(!target.closest("#nav-side-menu") && !target.closest("#nav-side-menu-icon")) {
                    this.showMenu = false;
                }
            }
        });

        this._userResolverService
            .userInfo
            .subscribe({ next: res => this.userInfo = res });
    }

    public ngOnInit() {
        this.environment = environment.production ? "PROD" : "TEST";
        this._notificationClient
            .notifications_GetUnreadUserNotifications(NotificationTypeEnum.WhatsNew)
            .subscribe({
                next: res => {
                    this.whatsNewNotifications = res;
                    if (res?.length > 0) {
                        this.isReleaseNoteModalVisible = Object.assign({}, true);
                    }
                },
                error: err => console.log(err)
            })
            .add(() => { this.isLoading = false; this._cdf.markForCheck(); })
    }

    public showAudit(): boolean {
        return this.userProfileService.hasPermission(UserRole.AddAudit);
    }

    public showOrder(): boolean {
        return this.userProfileService.hasPermission(UserRole.AddOrder);
    }

    public showEmergency(): boolean {
        return this.userProfileService.hasPermission(UserRole.EmergencyDashboard);
    }

    public showFull(): boolean {
        return this.userProfileService.hasPermission(UserRole.FullDashboard);
    }

    public onLogout(): void {
        this._cookieService.delete('storeNumber');
        this.authService.logout({ logoutParams: { returnTo: this.doc.location.origin } });
    }

    public goToProfile(): void {
        this.showMenu = false;
        this.showDropdownMenu = false;
        this._router.navigateByUrl('/profile');
    }

    public getReleaseNotes(): void {
        this.showMenu = false;
        this.showDropdownMenu = false;
        this.isLoading = true;
        this.isReleaseNoteModalVisible = Object.assign({}, true);
        this._notificationClient
            .notifications_GetAllNotifications(NotificationTypeEnum.WhatsNew, false)
            .subscribe({
                next: res => {
                    this.whatsNewNotifications = res;
                },
                error: err => console.log(err.error.error)
            })
            .add(() => this.isLoading = false)
    }
}
