import { Injectable, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { TranslocoService } from '@jsverse/transloco';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserResolverService {
    private _translate = inject(TranslocoService);
    private authService = inject(AuthService);

    private readonly namespace: string = "https://statusnow.com/";

    private activeLanguage: string | undefined;
    private userInfoSubject$ = new ReplaySubject<UserInfo>(1);
    public userInfo = this.userInfoSubject$.asObservable().pipe(distinctUntilChanged());

    constructor() {
        this.authService.idTokenClaims$.subscribe(x => {
            if(x) {
                let userInfo = new UserInfo();
                userInfo.StoreId = x[this.namespace + "storeNumber"];
                userInfo.CustomerId = x[this.namespace + "customerID"];
                userInfo.UserId = x.sub ?? "";
                userInfo.Email = x[this.namespace + "email"];
                userInfo.profileImage = x.picture ?? "";
                userInfo.fullname = x[this.namespace + "name"];
                userInfo.IsASM = x[this.namespace + "isASM"];
                userInfo.FirstName = x.given_name ?? "";
                userInfo.LastName = x.family_name ?? "";
                userInfo.DefaultLanguage = x[this.namespace + "defaultLanguage"];
                userInfo.MobileNumber = x[this.namespace + "mobileNumber"];
                this.userInfoSubject$.next(userInfo);

                /// if userInfo.DefaultLanguage is not null, use it
                /// if null, use browser language
                if (userInfo.DefaultLanguage) {
                    this.activeLanguage = userInfo.DefaultLanguage;
                }
                else {
                    let browserLanguage = navigator.language.split('-')[0];
                    let supportedLanguages = this._translate.getAvailableLangs() as string[];
                    this.activeLanguage = supportedLanguages.indexOf(browserLanguage) !== -1
                        ? browserLanguage
                        : 'en';
                }

                let activeLang = this._translate.getActiveLang();
                if (activeLang != this.activeLanguage) {
                    this._translate.setActiveLang(this.activeLanguage ?? 'en');
                }
            }
        });
    }
}

export class UserInfo {
    StoreId: string | undefined;
    CustomerId: string | undefined;
    UserId: string | undefined;
    Email: string = "";
    profileImage: string = "";
    fullname: string = "";
    IsASM: boolean = false;
    FirstName: string = "";
    LastName: string = "";
    MobileNumber: string = "";
    DefaultLanguage: string = "";
}
