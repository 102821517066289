import { ApplicationConfig, ErrorHandler, Injectable, importProvidersFrom, isDevMode, provideZoneChangeDetection, inject } from "@angular/core";
import { provideRouter } from "@angular/router";
import { HttpClient, provideHttpClient, withInterceptors } from "@angular/common/http";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { BrowserModule } from "@angular/platform-browser";

import { authHttpInterceptorFn, provideAuth0 } from "@auth0/auth0-angular";

import { provideNgxMask } from "ngx-mask";
import { provideToastr } from "ngx-toastr";
import { provideCharts, withDefaultRegisterables } from "ng2-charts";

import { mainRoutes } from "./app.routes";

import { VersionCheckService } from "./core/services/version-check.service";
import { UserResolverService } from "./core/services/user-resolver.service";
import { SpinnerService } from "./core/spinner/spinner.service";

import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";
import { CookieService } from "ngx-cookie-service";

import { environment } from "environments/environment";
import { API_BASE_URL } from "./core/services/dataservice.svc";
import { Translation, TranslocoLoader, provideTransloco } from "@jsverse/transloco";
import { ConfirmationService } from "primeng/api";
import { ApplicationInsightsService } from "./core/services/telemetry.service";
import { providePrimeNG } from "primeng/config";
import { definePreset } from '@primeng/themes';

import Aura from '@primeng/themes/aura';
import Nora from '@primeng/themes/nora';
import Lara from '@primeng/themes/lara';

const customPreset = definePreset(Aura, {
    semantic: {
        primary: Aura.primitive['blue'],
        surface: 'gray',
        darkTheme: false,
        colorScheme: {
            light: {
                formField: {
                    borderRadius: '.375rem',
                    paddingY: '0.75rem',
                    sm: {
                        fontSize: "0.875rem",
                        paddingX: "0.625rem",
                        paddingY: "0.625rem"
                    },
                    lg: {
                        fontSize: "1.125rem",
                        paddingX: "0.875rem",
                        paddingY: "0.875rem"
                    },
                    focusRing: {
                        width: "1px",
                        style: "solid",
                        color: "{primary.color}",
                        offset: "0",
                        shadow: "none"
                    },
                },
                list: {
                    padding: "0.5rem 0.5rem",
                    gap: "4px",
                    header: {
                        padding: "0.75rem 1.25rem 0.5rem 1.25rem"
                    },
                    option: {
                        padding: "0.75rem 0.75rem",
                        borderRadius: "{border.radius.sm}"
                    },
                    optionGroup: {
                        padding: "0.75rem 0.75rem",
                        fontWeight: "600"
                    }
                },
                navigation: {
                    list: {
                        padding: "0.5rem 0.5rem",
                    },
                    item: {
                        padding: "0.75rem 0.75rem",
                        borderRadius: "{border.radius.sm}",
                    },
                    submenuLabel: {
                        padding: "0.75rem 0.75rem",
                        fontWeight: "600"
                    },
                    submenuIcon: {
                        size: "0.875rem"
                    }
                },
                overlay: {
                    select: {
                        borderRadius: "{border.radius.md}",
                        shadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)"
                    },
                    popover: {
                        borderRadius: "{border.radius.md}",
                        padding: "0.75rem",
                        shadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)"
                    },
                    modal: {
                        borderRadius: "{border.radius.md}",
                        padding: "1.25rem",
                        shadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)"
                    },
                    navigation: {
                        shadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)"
                    }
                },
                surface: {
                    0: '#ffffff',
                    50: '{zinc.50}',
                    100: '{zinc.100}',
                    200: '{zinc.200}',
                    300: '{zinc.300}',
                    400: '{zinc.400}',
                    500: '{zinc.500}',
                    600: '{zinc.600}',
                    700: '{zinc.700}',
                    800: '{zinc.800}',
                    900: '{zinc.900}',
                    950: '{zinc.950}'
                }
            }
        }
    }
});


@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);


  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            BrowserModule
        ),
        {
            provide: ErrorHandler,
            useClass: ApplicationinsightsAngularpluginErrorService
        },
        {
            provide: API_BASE_URL,
            useValue: environment.backendServerUrl
        },
        CookieService,
        VersionCheckService,
        UserResolverService,
        ApplicationInsightsService,
        ConfirmationService,
        SpinnerService,
        
        provideAnimationsAsync(),
        providePrimeNG({
            theme: {
                preset: customPreset,
                options: {
                    darkModeSelector: false,
                    cssLayer: {
                        name: 'primeng',
                        order: 'tailwind-base, primeng, tailwind-utilities'
                    }
                }
            }
        }),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(mainRoutes),
        provideAuth0({ ...environment.auth }),
        provideHttpClient(withInterceptors([authHttpInterceptorFn])),
        provideAnimationsAsync(),
        provideToastr(),
        provideNgxMask(),
        provideCharts(withDefaultRegisterables()),
        provideTransloco({
            config: {
                availableLangs: ['en', 'es', 'fil'],
                defaultLang: 'en',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            },
            loader: TranslocoHttpLoader
        })
    ]
}